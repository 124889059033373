import { useState, useRef, useEffect, ReactNode, HTMLProps } from "react";
import { useGetContactInfo as getContact } from "api/initial/useGetContactInfo";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import classNames from "classnames";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import {
  SelectedContact,
  useGetContractIdData,
} from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import {
  BsCheck,
  BsCircleFill,
  BsFillPersonFill,
  BsLink,
  BsList,
  BsPersonFill,
} from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import { initials } from "utils/initials";
import { TextInput } from "../TextInput";
import { useGetContactInfo } from "./api/useGetContacts";

export const ContactSelector = () => {
  const { selectedContact, setSelectedContactId, setSelectedContact } =
    useGetContractIdData();
  const { data, loading } = getContact(false);
  const { linkedContact } = useKeycloak();
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  const selectorRef = useRef<HTMLDivElement>(null);
  const theContact = {
    id: data?._contactId,
    userName: data?.name,
    contactId: data?._contactId,
    initials: "",
  } as SelectedContact;

  const [isOpen, setIsOpen] = useState(false);
  const setContact = (contact: SelectedContact) => {
    setSelectedContact(contact);
    setSelectedContactId(contact.id);
    if (portfolioId) navigate("/overview", { replace: true });
    setIsOpen(false);
  };

  return (
    <>
      <div className="relative">
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className={classNames(
            "rounded-full w-10 h-10 p-2 bg-nim text-pine flex justify-center items-center cursor-pointer"
          )}
        >
          <BsList className="w-full h-full" />
        </div>

        {isOpen && (
          <SearchContacts
            linkedContact={linkedContact}
            selectedContact={selectedContact}
            onSelect={(contact) => setContact(contact)}
          />
        )}
      </div>
      {isOpen && (
        <div
          className="fixed left-0 top-0 w-full h-full z-[100] bg-transparent"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
};

interface SearchContactsProps {
  linkedContact: string | undefined;
  selectedContact: SelectedContact;
  onSelect: (contact: SelectedContact) => void;
}
const SearchContacts = ({
  linkedContact,
  onSelect,
  selectedContact,
}: SearchContactsProps) => {
  const logOut = () => keycloakService.onAuthLogout();
  const [isLoaded, setIsLoaded] = useState(false);
  const [api, setApi] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const [searchName, setSearchName] = useState<string>();

  const { loading, data } = useGetContactInfo(linkedContact, searchName, api);
  const isLoading = loading;

  const handleSubmit = () => {
    setSearchName(nameRef.current?.value);
    setApi(true);
  };
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <div
      id="userMenuPopup"
      className={classNames(
        "z-[101] absolute cursor-default right-0 mt-1 bg-nim text-pine rounded-xl shadow-card border h-auto overflow-hidden p-1 transition-[max-height] duration-300 max-h-0",
        { "max-h-[600px]": isLoaded }
      )}
    >
      <form
        className="flex justify-evenly items-center my-1 gap-1 outline-hidden"
        name="searchName"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextInput placeholder="Search.." ref={nameRef} />
        <Button
          icon={<FaSearch />}
          loading={isLoading}
          onClick={() => handleSubmit()}
        >
          Search
        </Button>
      </form>
      {isLoading && <LoadingIndicator center />}
      <div className="grid divide-y">
        {!isLoading && data?.contact && (
          <ContactRow
            isLinkedContact
            onSelect={() =>
              onSelect({
                id: data.contact?.id ?? 0,
                contactId: data.contact?.contactId ?? "",
                userName: data.contact?.name ?? "",
                initials: initials(data.contact?.name ?? ""),
              })
            }
            contact={data.contact}
            isSelected={data.contact.id === selectedContact.id}
          />
        )}

        {!isLoading &&
          data.contacts?.map((x, i) => {
            const isSelected = selectedContact.id === x.id;
            return (
              <ContactRow
                onSelect={() =>
                  onSelect({
                    id: x.id,
                    contactId: x.contactId,
                    userName: x.name,
                    initials: initials(x.name),
                  })
                }
                key={i}
                contact={x}
                isSelected={isSelected}
              />
            );
          })}
      </div>
      <div className="w-full">
        <Button loading={isLoading} onClick={() => logOut()}>
          Log out
        </Button>
      </div>
    </div>
  );
};

interface ContactRowProps {
  onSelect: () => void;
  isSelected?: boolean;
  isLinkedContact?: boolean;
  contact: {
    id: string | number | undefined;
    name: string;
  };
}
const ContactRow = ({
  contact,
  isSelected,
  isLinkedContact,
  onSelect,
}: ContactRowProps) => {
  return (
    <div
      onClick={() => onSelect()}
      className={classNames(
        "flex justify-between cursor-pointer items-center p-2 hover:bg-gray-100 hover:rounded-md overflow-hidden",
        {
          "font-bold": isSelected,
        }
      )}
    >
      <div className="flex justify-start items-center">{contact.name}</div>
      {isSelected && <BsCircleFill className="text-[6px]" />}
    </div>
  );
};

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  onClick?: () => void;
  children?: ReactNode;
  icon?: ReactNode;
  loading?: boolean;
}
export const Button = ({
  onClick,
  disabled = false,
  children,
  icon,
  loading,
}: ButtonProps) => {
  return (
    <button
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disabled}
      className={classNames(
        "py-1.5 px-3 rounded-full bg-pine text-white flex justify-center items-center gap-2",
        {
          "cursor-default": !onClick,
        }
      )}
    >
      {icon && loading ? (
        <Spinner
          className={classNames("w-4 h-4 text-pine animate-spin fill-white")}
        />
      ) : (
        icon
      )}
      {children}
    </button>
  );
};

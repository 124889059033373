import { gql, useQuery } from "@apollo/client";

const CONTACT_INFO_QUERY = gql`
  query GetContactInfo($contactName: String, $contactId: Long, $limit: Int) {
    contacts(
      contactTypeCode: "1"
      name: $contactName
      status: "A"
      resultSize: $limit
    ) {
      id
      name
      contactId
    }
    contact(id: $contactId) {
      id
      name
      contactId
    }
  }
`;

export interface Contact {
  id: string | number;
  contactId: string;
  name: string;
}
export interface GetContactsQuery {
  contacts: Contact[];
  contact: Contact;
}

export const useGetContactInfo = (
  linkedContact: string | undefined,
  searchName: string | undefined,
  apiCall = false,
  limit = 10
) => {
  const { loading, error, data } = useQuery<GetContactsQuery>(
    CONTACT_INFO_QUERY,
    {
      variables: {
        limit,
        contactId: linkedContact,
        contactName: searchName ? "%" + searchName + "%" : searchName,
      },
      fetchPolicy: apiCall ? "cache-and-network" : "cache-first",
    }
  );
  return {
    loading,
    error,
    data: { contact: data?.contact, contacts: data?.contacts },
  };
};

import { ReactNode } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { NoPortfolios } from "./components/NoPortfolios";

interface PortfolioGuardProps {
  children: ReactNode;
}

export const PortfolioGuard = ({ children }: PortfolioGuardProps) => {
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] }, loading } =
    useGetContactInfo(false, selectedContactId);

  if (loading)
    return (
      <div className="flex justify-center items-center h-32 w-full">
        <LoadingIndicator center />
      </div>
    );
  if (portfolios.length === 0) {
    return <NoPortfolios />;
  }

  return <>{children}</>;
};

import { ReactComponent as NorselabLogo } from "assets/logo.svg";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

export const Logo = () => {
  const { portfolioId } = useParams();
  const isSm = useMatchesBreakpoint("sm");
  const navigate = useNavigate();

  return (
    <div
      className="w-32 h-10 cursor-pointer flex items-center"
      onClick={() =>
        navigate(
          portfolioId ? `/portfolio/${portfolioId}/overview` : "/overview"
        )
      }
    >
      <NorselabLogo />
    </div>
  );
};
